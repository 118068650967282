import { Paginatior } from "components/Paginatior";
import { ServiceCard } from "components/ServiceCard";
import { SmallLoading } from "components/SmallLoading";
import { useCategoryService } from "hooks/useCategoryService";
import { Business } from "models/business";
import { Category } from "models/category";
import { Service } from "models/service";
import { ServiceRequest } from "models/serviceRequest";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import manosComfenalco from "../../../assets/images/manosComfenalco.png";
import { IsCategoryAvailable } from "utils/CategoryAvailable";

interface ServiceTypeProps {
  selectedCategory?: Category;
  selectedBusiness?: Business;
  isLoadingCategories?: any;
  pageableParam: number;
  validateRecaptcha?: boolean;
}

export const ServiceType = ({
  selectedCategory,
  selectedBusiness,
  isLoadingCategories,
  pageableParam,
  validateRecaptcha
}: ServiceTypeProps) => {
  const { authData, isLogged } = useSelector((state: RootState) => state.auth);
  const { getCategoryServices, isLoadingService } = useCategoryService();

  const [services, setServices] = useState<Service[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const { token } = useSelector((state: RootState) => state.jwt);

  // Define el rango de fechas
  const isWithinRange = selectedCategory && IsCategoryAvailable(selectedCategory);

  const idNegocio = selectedCategory?.idNegocioCdaNegocio;

  useEffect(() => {
    if (!idNegocio) {
      // No hacer nada si no hay idNegocio disponible
      console.log("No hay idNegocio disponible");
      
      return;
    }

    const getServices = async () => {
      const requestServices: ServiceRequest = {
        idCategoria: selectedCategory?.idCategoria,
        pageNumber: page - 1,
        pageSize: pageableParam,
        consulta: null,
        idMunicipio: null,
      };

      const services = await getCategoryServices(requestServices);

      setTotalPages(services?.totalPages || 1);
      setServices(services?.content || []);
    };

    getServices();
  }, [idNegocio, page, pageableParam, selectedCategory?.idCategoria]); // Dependencias adicionales para asegurar que el efecto se ejecute correctamente

  return (
    <div className="w-full px-4">
      {isLoadingService || isLoadingCategories ? (
        <div className="h-80 w-full">
          <SmallLoading />
        </div>
      ) : (
        <>
          {!isWithinRange ? (
            <>
              {services?.length <= 0 ? (
                <div className="flex justify-center h-80">No hay servicios para mostrar.</div>
              ) : (
                <div className="flex flex-wrap justify-around py-6">
                  {services?.map((service) => (
                    <div className="py-3" key={service.idServicio}>
                      <ServiceCard
                        color={selectedBusiness?.color!}
                        service={service}
                        selectedCategory={selectedCategory}
                        isLogged={isLogged}
                        showValue={true}
                        screenType={service?.tipoPantalla}
                      />
                    </div>
                  ))}
                </div>
              )}
            </>
          ) : (
            <div className="flex flex-col w-full items-center pt-4">
              <div className="flex flex-col w-2/3 items-center rounded-lg text-center bg-principal-350 p-2">
                <img src={manosComfenalco} className="h-16 w-16" />
                <p className="text-principal-600 font-bold">
                  {selectedCategory?.mensajeInactividad && selectedCategory?.mensajeInactividad}
                </p>
              </div>
            </div>
          )}
        </>
      )}

      {services?.length > 0 && !isWithinRange && (
        <div className="flex justify-center pb-4">
          <Paginatior totalPages={totalPages} page={page} setPage={setPage} />
        </div>
      )}
    </div>
  );
};
