import { FormControl, InputLabel, MenuItem, Select, createTheme } from '@mui/material';
import { Paginatior } from 'components/Paginatior';
import { ServiceCard } from 'components/ServiceCard';
import { SmallLoading } from 'components/SmallLoading';
import { useCategory } from 'hooks/useCategory';
import { useService } from 'hooks/useService'
import { Category } from 'models/category';
import { Service } from 'models/service';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import manosComfenalco from '../../assets/images/manosComfenalco.png';
import { ThemeProvider } from '@emotion/react';


interface SearchProps {
  pageableParam: number;
}

export const Search = ({
  pageableParam
}: SearchProps) => {
  const { service, city } = useSelector(
    (state: RootState) => state.searchServices);

  const { isLogged } = useSelector((state: RootState) => state.auth);

  const { searchServices, isLoadingSearch } = useService();
  const { getActiveCategories } = useCategory();

  const [services, setServices] = useState<Service[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);

  const [selectedCategory, setSelectedCategory] = useState<number>();

  const [page, setPage] = useState(1);

  const [totalPages, setTotalPages] = useState(1);

  const greenTheme = createTheme({
    palette: {
      primary: {
        main: '#135F2C',
      },
    },
  });

  const handleChange = (data: any) => {
    setSelectedCategory(Number(data?.target?.value));
  };

  useEffect(() => {
    const onSearchServices = async () => {
      const services = await searchServices({
        consulta: service ? service : undefined,
        idMunicipio: city ? city : undefined,
        idCategoria: selectedCategory ? selectedCategory : undefined,
        pageNumber: page - 1,
        pageSize: pageableParam,
      })

      setTotalPages(services?.totalPages);
      setServices(services?.content)
    }

    const activeCategories = async () => {
      const categories = await getActiveCategories();
      categories && setCategories(categories)
    }

    onSearchServices()
    activeCategories()
  }, [isLogged, page])

  useEffect(() => {
    const onSearchServices = async () => {
      setPage(1)
      const services = await searchServices({
        consulta: service ? service : undefined,
        idMunicipio: city ? city : undefined,
        idCategoria: selectedCategory ? selectedCategory : undefined,
        pageNumber: page - 1,
        pageSize: pageableParam,
      })

      setTotalPages(services?.totalPages);
      setServices(services?.content)

    }
    onSearchServices()

  }, [service, city, selectedCategory])


  return (
    <ThemeProvider theme={greenTheme}>
      <div className='flex flex-wrap justify-around p-8'>
        <div className='w-full p-4 rounded-lg mb-2 shadow'>
          <FormControl className='w-full' size="small">
            <InputLabel id='buscar-por-categoria'>Buscar por categoría</InputLabel>
            <Select
              labelId='buscar-por-categoria'
              label='Buscar por categoria'
              defaultValue={selectedCategory}
              onChange={handleChange}
            >
              <MenuItem value={''}>Buscar por categoría</MenuItem>
              {
                categories?.map((category, index) => (
                  <MenuItem key={index} value={category?.idCategoria}>{category?.nombre}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </div>
        <div className='flex flex-wrap justify-around w-full rounded-lg p-4 shadow'>
          <>
            {
              isLoadingSearch ? <SmallLoading /> :
                <>
                  {
                    services?.length > 0 ? (
                      <>
                        {services?.map((service, index) => (
                          <div className='py-2 text-principal-600' key={index}>
                            <ServiceCard service={service} isLogged={isLogged} screenType={service?.tipoPantalla} />
                          </div>
                        ))}
                      </>
                    ) : (
                      <div className='flex flex-col w-full items-center pt-4'>
                        <div className='flex flex-col w-2/3 items-center rounded-lg text-center bg-principal-350 p-2'>
                          <img src={manosComfenalco} className="h-16 w-16" />
                          <p className='text-principal-600 font-bold'>No hay servicios disponibles.</p>
                        </div>
                      </div>
                    )
                  }
                  {services?.length > 0 && (
                    <div className="flex justify-center w-full py-4">
                      <Paginatior totalPages={totalPages} page={page} setPage={setPage} />
                    </div>
                  )}
                </>
            }
          </>
        </div>
      </div>
    </ThemeProvider>

  )
}
