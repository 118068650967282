import { Typography } from '@mui/material'
import { MQuotas } from 'models/quotas'
import React, { useState } from 'react'
import { DetailModal } from './DetailModal';

interface QuotaCardProps {
    title: string;
    quotas: MQuotas | undefined;
    text?: string;
    monto?: string;
    tasa?: string;
    asesorInfo: any
}

export const GoodwillCreditCard = ({ quotas, title, text, monto, tasa, asesorInfo }: QuotaCardProps) => {

    const [isOpenModal, setIsOpenModal] = useState(false);

    const onClose = () => {
        setIsOpenModal(prev => !prev)
    }

    return (
        <div className='flex flex-col justify-center items-center w-full md:w-9/20 bg-white border rounded-xl shadow p-8'>
            <p className='font-bold text-principal-500'>{title}</p>
            {/* Renderizando firstParam como HTML si es necesario */}
            {text && (
                <span
                    className=''
                    color='primary'
                    dangerouslySetInnerHTML={{ __html: text }}
                />
            )}
            <Typography className='text-2xl' color='primary'>{monto}</Typography>
            <span  className='text-center text-principal-250 font-bold' color='primary'>{tasa} MV</span>

            <button
                onClick={() => setIsOpenModal(true)}
                className='bg-principal-250 rounded-lg text-sm py-2 px-2 text-white mt-2'
            >
                Ver información asesor
            </button>

            <DetailModal open={isOpenModal} onClose={onClose} infoAsesor={asesorInfo} />

        </div>
    )
}
