import React, { useRef, useState, useEffect } from 'react'
import { BusinessCard } from './BusinessCard';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import { Business } from 'models/business';
import { Category } from 'models/category';
import { CategoryCard } from './CategoryCard';
import { Loading } from './Loading';
import { SmallLoading } from './SmallLoading';

interface CategorySliderProps {
    selectedBusiness?: Business;
    activeCategories?: Category[];
    selectedCategoryId?: string;
    selectedCategory?: Category;
    setSelectedCategory?: any;
    isLoadingCategories?: boolean;
}

export const CategorySlider = ({ activeCategories = [], selectedBusiness, selectedCategory, setSelectedCategory, isLoadingCategories }: CategorySliderProps) => {

    const [mobile, setMobile] = useState(window.innerWidth <= 500);

    const handleWindowSizeChange = () => {
        setMobile(window.innerWidth <= 500);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const handleScroll = (scrollOffset: number) => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft += scrollOffset;
        }
    };

    return (
        <>
            <div className="flex w-full flex-row items-center px-3 shadow-md shadow-black/10">
                <ExpandCircleDownOutlinedIcon
                    className="h-10 w-10 mr-2 cursor-pointer text-principal-450 rotate-90"
                    onClick={() => handleScroll(-200)}
                />

                {
                    isLoadingCategories || !selectedBusiness?.idNegocio ? (
                        <SmallLoading />
                    ) : activeCategories?.length === 0 ? (
                        <div className="text-center w-full py-4">
                            <p className="text-gray-500">No hay categorias disponibles.</p>
                        </div>
                    ) : (
                        <div
                            className="flex w-full px-4 scroll-container overflow-hidden"
                            ref={scrollContainerRef}
                            style={{ justifyContent: activeCategories.length <= 4 && !mobile ? 'center' : '' }}
                        >
                            {activeCategories.map((category) => (
                                <CategoryCard
                                    key={category.idCategoria}
                                    category={category}
                                    setSelectedCategory={setSelectedCategory}
                                    selectedCategory={selectedCategory === category}
                                    selectedBusiness={selectedBusiness}
                                />
                            ))}
                        </div>
                    )
                }

                <ExpandCircleDownOutlinedIcon
                    className="h-10 w-10 ml-2 cursor-pointer text-principal-450 -rotate-90"
                    onClick={() => handleScroll(200)}
                />
            </div>
        </>
    );
};
