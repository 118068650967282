import React, { useEffect, useState } from "react";
import { Business } from "models/business";
import { Category } from "models/category";
import { ServiceType } from "./ScreenTypes/ServiceType";
import { Quotas } from "./ScreenTypes/Quotas";
import { Balances } from "./ScreenTypes/Balances";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { HotelsList } from "./ScreenTypes/HotelsList";
import { Spaces } from "./ScreenTypes/Spaces";
import { HousingSubsidy } from './ScreenTypes/HousingSubsidy';
import {
  ST_CUPOS,
  ST_ESPACIOS,
  ST_HOTEL,
  ST_KIT_ESCOLAR,
  ST_SALDOS,
  ST_SERVICIO,
  ST_SUB_MONETARIO,
  ST_SUB_VIVIENDA,
  ST_POSTULACION,
  PARAM_CANTIDAD_SERVICIOS_PAGINADO,
  ST_RE_CAPTCHA
} from "utils/constants";
import { MonetarySubsidy } from "./ScreenTypes/MonetarySubsidy";
import { HousingApplication } from './ScreenTypes/HousingApplication';
import { SchoolKit } from "./ScreenTypes/SchoolKit";
import { useParam } from "hooks/useParam";
import { ServiceTypeRecaptcha } from "./ScreenTypes/ServiceTypeRecaptcha";

interface ScreenTypeProps {
  selectedCategory?: Category;
  selectedBusiness?: Business;
  isLoadingCategories?: any;
}

export const ScreenType = ({
  selectedCategory,
  selectedBusiness,
  isLoadingCategories,
}: ScreenTypeProps) => {

  
  const { getParamData } = useParam();
  const [pageableParam, setPageableParam] = useState<number | null>(null); // Inicializa como null
  const [loading, setLoading] = useState<boolean>(true); // Estado para saber si estamos esperando la respuesta

  useEffect(() => {
    const fetchPageableParam = async () => {
      try {
        const param = await getParamData(PARAM_CANTIDAD_SERVICIOS_PAGINADO);
        const valorParametro = param?.data?.valorParametro;
        
        const pageSize = valorParametro && !isNaN(parseInt(valorParametro))
          ? parseInt(valorParametro)
          : 5;

        setPageableParam(pageSize);
        setLoading(false); // Cambiar el estado de carga cuando la respuesta esté lista
      } catch (error) {
        console.error("Error al obtener el parámetro:", error);
        setPageableParam(5); // Valor predeterminado en caso de error
        setLoading(false); // Cambiar el estado de carga
      }
    };

    if (pageableParam === null) {
      fetchPageableParam();
    }
  }, [pageableParam]);

  // Mientras `loading` sea verdadero, muestra un "cargando"
  if (loading) {
    return <div></div>;
  }


  const purpleTheme = createTheme({
    palette: {
      primary: {
        main: selectedBusiness?.color || "#000",
      },
    },
  });

  let mapScreenType: Map<string, JSX.Element> = new Map<string, JSX.Element>();

  mapScreenType.set(
    ST_SERVICIO,
    <ServiceType
      selectedCategory={selectedCategory}
      selectedBusiness={selectedBusiness}
      isLoadingCategories={isLoadingCategories}
      pageableParam={pageableParam ?? 5}
    />
  );

  mapScreenType.set(
    ST_RE_CAPTCHA,
    <ServiceTypeRecaptcha
      selectedCategory={selectedCategory}
      selectedBusiness={selectedBusiness}
      isLoadingCategories={isLoadingCategories}
      pageableParam={pageableParam ?? 5}
      validateRecaptcha={true}
    />
  );

  mapScreenType.set(ST_SALDOS, <Balances selectedCategory={selectedCategory} />);

  mapScreenType.set(ST_CUPOS, <Quotas selectedCategory={selectedCategory} />);

  mapScreenType.set(
    ST_HOTEL,
    <HotelsList
      pageableParam={pageableParam ?? 5}
      selectedBusiness={selectedBusiness}
      selectedCategory={selectedCategory} 
    />
  );

  mapScreenType.set(ST_ESPACIOS, <Spaces selectedCategory={selectedCategory} pageableParam={pageableParam ?? 5}/>);

  mapScreenType.set(ST_SUB_VIVIENDA, <HousingSubsidy selectedCategory={selectedCategory} />);

  mapScreenType.set(ST_SUB_MONETARIO, <MonetarySubsidy selectedBusiness={selectedBusiness} selectedCategory={selectedCategory} />);

  mapScreenType.set(
    ST_POSTULACION,
    <HousingApplication
      color={selectedBusiness?.color!}
      selectedCategory={selectedCategory}
    />,
  );
  mapScreenType.set(ST_KIT_ESCOLAR, <SchoolKit selectedCategory={selectedCategory} />);

  return (
    <ThemeProvider theme={purpleTheme}>
      {selectedCategory && mapScreenType.get(selectedCategory.tipoPantalla)}
    </ThemeProvider>
  );
};
